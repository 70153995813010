<template>
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.9999 4.99999C13.9999 4.86738 13.9472 4.74021 13.8535 4.64644C13.7597 4.55267 13.6325 4.49999 13.4999 4.49999H1.70692L4.85392 1.35399C4.9004 1.3075 4.93728 1.25231 4.96244 1.19157C4.9876 1.13083 5.00055 1.06573 5.00055 0.999991C5.00055 0.934247 4.9876 0.869147 4.96244 0.808407C4.93728 0.747668 4.9004 0.692479 4.85392 0.645991C4.80743 0.599503 4.75224 0.562627 4.6915 0.537467C4.63076 0.512308 4.56566 0.499359 4.49992 0.499359C4.43417 0.499359 4.36907 0.512308 4.30833 0.537467C4.24759 0.562627 4.1924 0.599503 4.14592 0.645991L0.145917 4.64599C0.099354 4.69244 0.0624111 4.74761 0.0372047 4.80836C0.0119982 4.8691 -0.000976563 4.93422 -0.000976562 4.99999C-0.000976563 5.06576 0.0119982 5.13088 0.0372047 5.19162C0.0624111 5.25237 0.099354 5.30754 0.145917 5.35399L4.14592 9.35399C4.1924 9.40048 4.24759 9.43736 4.30833 9.46251C4.36907 9.48767 4.43417 9.50062 4.49992 9.50062C4.56566 9.50062 4.63076 9.48767 4.6915 9.46251C4.75224 9.43736 4.80743 9.40048 4.85392 9.35399C4.9004 9.3075 4.93728 9.25231 4.96244 9.19157C4.9876 9.13083 5.00055 9.06573 5.00055 8.99999C5.00055 8.93425 4.9876 8.86915 4.96244 8.80841C4.93728 8.74767 4.9004 8.69248 4.85392 8.64599L1.70692 5.49999H13.4999C13.6325 5.49999 13.7597 5.44731 13.8535 5.35354C13.9472 5.25978 13.9999 5.1326 13.9999 4.99999Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconBack'
}
</script>

<style>
</style>
